import React, {useEffect, useState} from "react";

// importing the default material ui components.
import Grid from '@mui/material/Grid';
import {connect} from "react-redux";
import {mainPage} from "../actions/Profile";
import Wrapper from "../layout/Logged/Wrapper";

// default helpers and functions.
import {
	calculateNextOnDate,
	capitalizeFirstLetter,
	notSignedMandates,
	retrieveCollegiaAccessToken
} from "../utils/Helpers";

import Loading from "../components/Loading";
import CollegiaAccountCard from "../components/MainPage/CollegiaAccountCard";
import ExpectedRetirementLifestyleCardMainPage from "../components/MainPage/ExpectedRetirementLifestyleCardMainPage";
import ExpectedRetirementAgeCard from "../components/MainPage/ExpectedRetirementAgeCard";
import SecondRowCard from "../components/MainPage/SecondRowCard";
import StatePensionIcon from "../images/flag-icon.svg";
import ContributionIcon from "../images/pound-main-page-icon.svg";
import AutoEnrolIcon from "../images/auto-enrol-icon.svg";
import InvestmentIcon from "../images/sustainable-icon.svg";
import moment from "moment";
import {useTranslation} from "react-i18next";
import NewModalDesign from "../components/NewModalDesign";
import {useNavigate} from "react-router-dom";

/**
 * @param profile_data
 * @param t
 * @returns {string}
 */
function renderStatePensionTitle(profile_data = {
	state_pension: {
		percentage: 0,
	}
}, t = key => key): string {
	if (profile_data) {
		if (profile_data.state_pension) {
			if (parseInt(profile_data.state_pension.percentage) < 100) {
				return t("individual.partial_state_pension");
			}
		}
	}

	return t("individual.full_state_pension");
}

/**
 * @param profile_data
 * @param t
 * @returns {string|`${number} ${string} active`}
 */
function renderAutoEnrolmentTitle(profile_data = {
	individual_employers: [],
}, t = key => key,): string {

	if (profile_data?.individual_employers) {
		if (profile_data?.individual_employers.filter(employer => employer.active === true).length > 0) {
			let title =
				profile_data?.individual_employers.filter(employer => employer.active === true).length > 1 ?
					t("individual.employers") : t("individual.employer");
			return `${profile_data?.individual_employers.filter(employer => employer.active === true).length} ${title} ${t("individual.active")}`;
		}
	}

	return t("individual.no_connection");
}

/**
 * @returns {JSX.Element}
 * @param profile_data
 */
function renderEmployerContributions(profile_data = {
	individual_employers: [],
}): JSX.Element {

	let individual_employers = [], total = 0;

	if (profile_data) {
		individual_employers = profile_data.individual_employers ?? [];

		if (individual_employers && individual_employers.length > 0) {

			individual_employers = individual_employers.filter(employer => employer.active === true);

			if (individual_employers.length === 0) {
				return total = 0;
			} else {
				individual_employers.forEach((d = {
					employer: {
						papdis: [],
					}
				}) => {
					const {
						employer = {
							papdis: [],
						},
					} = d;

					if (employer) {
						const {
							papdis = []
						} = employer;

						if (papdis.length > 0) {
							total += (papdis[0]?.transaction?.total_contribution) ?? 0;
						}
					}
				});
				return "£ " + parseFloat(total).toFixed(2);
			}
		}
	}
	return "N/A";
}

/**
 * @param extra_contributions
 * @returns {{contribution_value: number}}
 */
function retrieveContributionObject(extra_contributions = []): {contribution_value: number} {
	let hasMonthly = extra_contributions.filter(e => e.contribution_type === "Monthly" && e.provider_status === "ACTIVE"),
		contributionObject = {
			contribution_value: 0,
		};

	if (hasMonthly.length > 0) {
		contributionObject = hasMonthly[hasMonthly.length - 1];
	} else {
		let hasOneOff = extra_contributions.filter(e => e.contribution_type === "One-off").sort(
			function (a, b) {
				return a.id - b.id;
			});
		if (hasOneOff.length > 0) {
			contributionObject = hasOneOff[hasOneOff.length - 1];
		}
	}

	return contributionObject;
}

/**
 * @param extra_contributions
 * @param t
 * @returns {*|string}
 */
function renderContributionTitle(extra_contributions = [], t = key => key): string {
	if (extra_contributions.length > 0) {
		let hasMonthly = extra_contributions.filter((e = {
				contribution_type: "",
				provider_status: "",
			}) =>
				e.contribution_type === "Monthly" && e.provider_status === "ACTIVE"
		);

		if (hasMonthly.length > 0) {
			return t("individual.your_pension.contribution_card.next_on", {
				date: calculateNextOnDate(retrieveContributionObject(extra_contributions)?.contribution_date).format("DD MMM").toUpperCase()
			});
		} else {
			let hasOneOff = extra_contributions.filter(e => e.contribution_type === "One-off");
			if (hasOneOff.length > 0) {
				return t("individual.your_pension.contribution_card.last_on", {
					date: moment(retrieveContributionObject(extra_contributions)?.created_at).format("DD MMM").toUpperCase(),
				});
			}
		}
	}

	return capitalizeFirstLetter(t("individual.tap_to_include").toLowerCase());
}

/**
 * @param extra_contributions
 * @returns {string}
 */
function renderContributionValue(extra_contributions = []): string {
	if (extra_contributions.length > 0) {
		return "£ " + parseFloat(retrieveContributionObject(extra_contributions)
			.contribution_value ?? 0)
			.toFixed(2)
			.toLocaleString({
				style: 'currency',
				currency: 'GBP',
			});
	}
	return "N/A";
}

/**
 * @param profile_loading
 * @param loading
 * @param profile_data
 * @param language
 * @returns {JSX.Element}
 * @constructor
 */
const MainPage = (
	{
		profile_loading = false,
		loading = false,
		profile_data = [],
		language = "",
	}
): JSX.Element => {
	const {t, i18n} = useTranslation(),
		[link, setLink] = useState(""),
		[open, setOpen] = useState(false),
		history = useNavigate();

	const yearlyCharge = profile_data?.individual_investment_approach?.investment_approach?.yearly_charge;

	useEffect(() => {
		notSignedMandates();
		i18n.changeLanguage(language);
	}, [
		language,
		i18n,
	]);

	useEffect(() => {


		window.$crisp.push(["set", "session:data", [
			[
				["category", "Individual"],
				['trial_period', `${profile_data?.trial_period ?? ""}.`],
				['individual_id', `${profile_data?.id}`]
			]
		]]);
		if (profile_data) {
			if (profile_data?.first_login) {
				setOpen(true);
				setLink("/active-pension/nin-address");
			} else if (!profile_data?.email_confirmation && profile_data?.email_confirmation !== undefined) {
				setOpen(true);
				setLink("/confirm-your-email");
			} else if (!profile_data?.mobile_confirmation && profile_data?.mobile_confirmation !== undefined) {
				setOpen(true);
				setLink("/confirm-your-mobile");
			}

			if (window.$crisp) {
				if (profile_data?.email && profile_data?.email !== "") {
					window.$crisp.push(["set", "user:email", profile_data?.email ?? ""]);
				}

				window.$crisp.push(["set", "user:nickname", [
					`${profile_data.forename} ${profile_data?.middle_name ?? ""} ${profile_data.surname}`
				]]);

				if (profile_data?.mobile !== 'N/A'
					&& profile_data?.mobile !== "" &&
					profile_data?.mobile !== null
					&& profile_data?.mobile !== undefined
				) {
					window.$crisp.push(["set", "user:phone", profile_data.mobile ?? null]);
				}

				// if (profile_data?.addresses) {
				// 	window.$crisp.push(["set", "session:data", [
				// 		[
				// 			["address", `${profile_data?.addresses[0]?.address1 ?? ""} ${profile_data?.addresses[0]?.address2 ?? ""} ${profile_data?.addresses[0]?.address4 ?? ""}`],
				// 			["category", "Individual"],
				// 			['trial_period', `${profile_data?.trial_period ?? ""}.`],
				// 			['individual_id', `${profile_data?.id}`]
				// 		]
				// 	]]);
				// }
			}
		}
	}, [profile_data]);

	if (profile_loading || loading) {
		return (
			<Loading
				absolute
			/>
		);
	}

	if (!retrieveCollegiaAccessToken()) {
		window.location = "/login";
	}
	
	return (
		<Wrapper
			history={history}
			hideFooter={true}
			t={t}
		>
			<Grid
				id={"main-page-design"}
			>
				<Grid
					container
					className={"main-page-container"}
					item
				>
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						item
						container
						justifyContent={'center'}
					>
						<ExpectedRetirementLifestyleCardMainPage
							my_projections={profile_data?.projection}
							className={"position1"}
							history={history}
							t={t}
						/>
						<CollegiaAccountCard
							profile_data={profile_data}
							history={history}
							t={t}
						/>
						<ExpectedRetirementLifestyleCardMainPage
							my_projections={profile_data?.projection}
							className={"position"}
							history={history}
							t={t}
						/>
						<ExpectedRetirementAgeCard
							age={profile_data?.expected_retirement_age}
							history={history}
							t={t}
						/>
					</Grid>
					<Grid
						lg={12}
						xl={12}
						sm={12}
						md={12}
						item
						container
						justifyContent={'center'}
						overflow={"scroll"}
						className={"second-row"}
					>
						<SecondRowCard
							pageTitle={t("individual.auto_enrolment_contributions")}
							title={renderAutoEnrolmentTitle(profile_data, t)}
							subTitle={renderEmployerContributions(profile_data)}
							cta={t("individual.main_page.view_my_employers")}
							pageLink={"/auto-enrolment"}
							history={history}
							icon={AutoEnrolIcon}
						/>
						<SecondRowCard
							pageTitle={t("individual.investment_approach")}
							title={profile_data?.individual_investment_approach?.investment_approach?.name}
							subTitle={
								yearlyCharge ? yearlyCharge + "% " + t("individual.yearly_charge") : "N/A"
							}
							cta={t("individual.main_page.understand_your_investment")}
							pageLink={"/investment-approach"}
							history={history}
							icon={InvestmentIcon}
						/>
						<SecondRowCard
							pageTitle={t("individual.include_state_pensions_question")}
							title={renderStatePensionTitle(profile_data, t)}
							subTitle={
								"£ " + parseFloat((
										profile_data
											?.state_pension
											?.percentage
										/ 100
									) *
									profile_data
										?.generic_projection_parameter
										?.generic_projection_parameter
										?.weekly_state_pension_value
								?? 0
								).toFixed(2) + " / " + t("individual.week")
							}
							cta={t("individual.learn_more")}
							pageLink={"/state-pension"}
							history={history}
							icon={StatePensionIcon}
						/>
						<SecondRowCard
							pageTitle={t("individual.add_contributions")}
							title={renderContributionTitle(profile_data?.extra_contributions, t)}
							subTitle={renderContributionValue(profile_data?.extra_contributions)}
							cta={t("individual.main_page.view_more")}
							pageLink={"/extra-contributions"}
							history={history}
							icon={ContributionIcon}
						/>
					</Grid>
				</Grid>
			</Grid>
			<NewModalDesign
				open={open}
				setOpen={setOpen}
				link={link}
			/>
		</Wrapper>
	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile = [],
		PotToggle = [],
		Languages = {},
	} = state;

	return {
		...Profile,
		...PotToggle,
		...Languages,
	};
};

/**
 * @param dispatch
 * @returns {{me: (function(): *), completeWalkthrough: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	mainPage: dispatch(mainPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);